import { createWebHistory, createRouter } from "vue-router";

const routes = [

    {
      path: '/:scrollTo?',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      props: true 
    },

    { path: '/armory', name: 'armory', component: () => import('@/views/Armory.vue'), props: true },
    { path: '/weapons', name: 'weapons', component: () => import('@/views/Weapons.vue'), props: true },
    { path: '/power', name: 'power', component: () => import('@/views/Power.vue'), props: true },
    { path: '/estate', name: 'estate', component: () => import('@/views/Estate.vue'), props: true },
    { path: '/fight:gangCode?', name: 'fight', component: () => import('@/views/Fight.vue'), props: true },
    { path: '/code', name: 'code', component: () => import('@/views/FightCode.vue'), props: true },
    { path: '/report', name: 'report', component: () => import('@/views/FightReport.vue'), props: true },
    { path: '/achieve', name: 'achieve', component: () => import('@/views/Achieve.vue'), props: true },
    { path: '/daily', name: 'daily', component: () => import('@/views/Daily.vue'), props: true },
    { path: '/quest', name: 'quest', component: () => import('@/views/Missions.vue'), props: true },
    { path: '/merc', name: 'merc', component: () => import('@/views/Merc.vue'), props: true },
    { path: '/shop', name: 'shop', component: () => import('@/views/Shop.vue'), props: true },
    // { path: '/shop1', name: 'shop1', component: () => import('@/views/Shop1.vue'), props: true },
    // { path: '/shop2', name: 'shop2', component: () => import('@/views/Shop2.vue'), props: true },
    // { path: '/test1', name: 'test1', component: () => import('@/views/Test1.vue'), props: true },
    // { path: '/test2', name: 'test2', component: () => import('@/views/Test2.vue'), props: true },
    { path: '/help', name: 'help', component: () => import('@/views/Help.vue'), props: true },
    { path: '/rank', name: 'rank', component: () => import('@/views/Rank.vue'), props: true },
    { path: '/boost', name: 'boost', component: () => import('@/views/Boost.vue'), props: true },
    { path: '/vip', name: 'vip', component: () => import('@/views/Vip.vue'), props: true },
    { path: '/settings', name: 'settings', component: () => import('@/views/Settings.vue'), props: true },
    { path: '/travel', name: 'travel', component: () => import('@/views/Travel.vue'), props: true },
    { path: '/restore', name: 'restore', component: () => import('@/views/Restore.vue'), props: true },
    { path: '/invite', name: 'invite', component: () => import('@/views/Invite.vue'), props: true },
    { path: '/train', name: 'train', component: () => import('@/views/Train.vue'), props: true },
    { path: '/levelup', name: 'levelup', component: () => import('@/views/LevelUp.vue'), props: true },
    { path: '/info', name: 'info', component: () => import('@/views/Info.vue'), props: true },
    // { path: '/profile', name: 'profile', component: () => import('@/views/Profile.vue'), props: true },
    { path: '/guide', name: 'guide', component: () => import('@/views/Guide.vue'), props: true, children: [
        { path: '', name: 'guide-intro', component: () => import('@/views/Guide/_Intro.vue') },
        { path: 'info', name: 'guide-info', component: () => import('@/views/Guide/Info.vue') },
        { path: 'gang', name: 'guide-gang', component: () => import('@/views/Guide/Gang.vue') },
        { path: 'merc', name: 'guide-merc', component: () => import('@/views/Guide/Merc.vue') },
        { path: 'weap', name: 'guide-weap', component: () => import('@/views/Guide/Weap.vue') },
        { path: 'prop', name: 'guide-prop', component: () => import('@/views/Guide/Prop.vue') },
        { path: 'fight', name: 'guide-fight', component: () => import('@/views/Guide/Fight.vue') },
        { path: 'travel', name: 'guide-travel', component: () => import('@/views/Guide/Travel.vue') },
        { path: 'jobs', name: 'guide-jobs', component: () => import('@/views/Guide/Jobs.vue') },
        { path: 'restart', name: 'guide-restart', component: () => import('@/views/Guide/Restart.vue') },
        { path: 'cash', name: 'guide-cash', component: () => import('@/views/Guide/Cash.vue') },
        // { path: 'reset', name: 'guide-reset', component: () => import('@/views/Guide/Reset.vue') },
        // { path: 'treasury', name: 'guide-treasury', component: () => import('@/views/Guide/Treasury.vue') },
        { path: 'roadmap', name: 'guide-roadmap', component: () => import('@/views/Guide/Roadmap.vue') },
    ] },

    // { path: '/test', name: 'test', component: () => import('@/views/Test.vue') },

    // { path: '/collect', name: 'collect', component: () => import('@/views/Collect.vue'), props: true },

    // { path: '/dash', name: 'dash', component: () => import('@/views/Dash.vue'), props: true, children: [
    //     { path: '', name: 'list', component: () => import('@/views/List.vue'), props: true },
    //     { path: 'user', name: 'user', component: () => import('@/views/Profile.vue'), props: true },
    //     { path: 'coin/:CoinId', name: 'coin', component: () => import('@/views/Coin.vue'), props: true, children: [
    //         { path: '', name: 'gen', component: () => import('@/views/Coin/General.vue'), props: route => ({ CoinId: route.params.CoinId }) },
    //         { path: 'liq', name: 'liq', component: () => import('@/views/Coin/Liquidity.vue'), props: route => ({ CoinId: route.params.CoinId }) },
    //         { path: 'soc', name: 'soc', component: () => import('@/views/Coin/Social.vue'), props: route => ({ CoinId: route.params.CoinId }) },
    //         { path: 'all', name: 'all', component: () => import('@/views/Coin/Allocation.vue'), props: route => ({ CoinId: route.params.CoinId }) },
    //         { path: 'set', name: 'set', component: () => import('@/views/Coin/Settings.vue'), props: route => ({ CoinId: route.params.CoinId }) },
    //     ] },
    // ] },
    // { path: '/wiz', name: 'wiz', component: () => import('@/views/Wizard.vue'), children: [
    //     { path: '', name: 'wiz1', component: () => import('@/views/Wizard/Step1.vue') },
    //     { path: '2', name: 'wiz2', component: () => import('@/views/Wizard/Step2.vue') },
    //     { path: '3', name: 'wiz3', component: () => import('@/views/Wizard/Step3.vue') },
    //     { path: '4', name: 'wiz4', component: () => import('@/views/Wizard/Step4.vue') },
    //     { path: '5', name: 'wiz5', component: () => import('@/views/Wizard/Step5.vue') },
    // ] },


    // { path: '/auth', name: 'auth', component: () => import('@/views/Auth.vue') },
    // { path: '/exit', name: 'exit', component: () => import('@/views/Exit.vue') },
    
  ]

  const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        
          if (savedPosition) {
            return savedPosition;
          } else if (to.hash) {
            // alert('to.hash='+to.hash);
            // setTimeout(() => {
              const element = document.querySelector(to.hash);
              if (element) {
                // alert('element');
                element.scrollIntoView({ behavior: 'smooth' });
                // resolve({ x: 0, y: 0 });
            //   } else {
                // resolve({ x: 0, y: 0 });
              }
            // }, 500);
          } else {
            // return { x: 0, y: 0 }
            return { top: 0 }
          }
        // });
      },
    // scrollBehavior (to, from, savedPosition) {
    //     return { top: 0 }
    // }
  });
  
//   router.beforeEach((to, from, next) => {
//     const publicPages = [
//         '/', 
//         // '/wiz', '/wiz/2','/wiz/2','/wiz/3','/wiz/4','/wiz/5', // TEMP!
//         '/auth', '/logout' // , '/signin1', '/signup', '/signup1'
//     ]; //  '/home'
//     const authRequired = !publicPages.includes(to.path);
//     const loggedIn = localStorage.getItem('user');
//     // trying to access a restricted page + not logged in
//     // redirect to login page
//     if (authRequired && !loggedIn) {
//       next('/auth');
//     } else {
//       next();
//     }
//   });
  
  export default router;