import { createApp } from 'vue'
// import { createStore } from 'vuex';
import App from './App.vue'

import router from "./router";
// import i18n from './lang';
// import store from "./store";

// import { createRouter, createWebHistory } from 'vue-router'

// Include the Telegram WebApp mock only in development
if (process.env.NODE_ENV === 'development') {
    require('./mock');
  }

import Globals from './func';
const app = createApp(App);
app.mixin(Globals);
app
.use(router)
// .use(store)
// .use(i18n)
.mount('#app')