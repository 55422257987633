<template>
<div v-if="!isStandalone" class="
    bg-slate-900 bg-opacity-95 text-white md:w-[80%] 
    min-h-screen 
    md:max-w-[700px] w-full mx-auto md:border-l md:shadow-2xl md:shadow-slate-950
    md:border-r md:border-slate-700">

    <div class="pt-10 max-w-[600px] md:w-[80%] mx-auto">
        <p class="text-sm uppercase mb-5 opacity-50 text-center">Groundbreaking Social MMO Adventure</p>
        <img src="@/assets/logo/dt.png" class="w-24 mx-auto mb-2">

        <div v-if="isIOS">

            <p class="px-10 my-4 text-xl text-center">Add to Home Screen</p>
            <p class="px-10 my-4 text-center">Install this app to enjoy enhanced mobile experience:</p>
            <div class="w-full text-center">

                <div class="rounded-md p-3">
                    <div class="bg-slate-800 rounded-md p-4 mb-3">
                        <h3 class="mb-4">1. In your browser, tap the Share button:</h3>
                        <img src="@/assets/app-0.png" class="max-w-[300px] w-[80%] mx-auto">
                    </div>

                    <div class="bg-slate-800 rounded-md p-4 mb-3">
                        <h3 class="mb-4">2. Press "Add to Home Screen"</h3>
                        <img src="@/assets/app-1.png" class="max-w-[300px] w-[80%] mx-auto">
                    </div>

                    <div class="bg-slate-800 rounded-md p-4 mb-5 px-8">
                        <h3 class="mb-4">3. Find app icon on the Home Screen</h3>
                        <div>Launch the game any time from your home screen.</div>
                    </div>
                </div>

            </div>

        </div>
        <div v-else-if="isAndroid">

            <img src="@/assets/app-go.png" class="max-w-[250px] w-[80%] mx-auto">
            <p class="px-10 mb-4 text-xl text-center">Mobile Experience Recommended</p>
            <p class="px-10 my-4 text-center">Install DeGangs to add an icon on your home screen and enjoy enhanced mobile experience:</p>
            <div class="w-full text-center mb-5">

                <div v-if="!showAndroidLoader">
                    <button @click="showAndroidInstallLoader" class="but-2 but-a">
                    Download App
                    </button>
                </div>
                <div v-else>
                    <div v-if="!doneAndroidLoader" class="text-orange-400 my-5">
                        <p>Downloading the app...</p>
                    </div>
                    <div v-else class="android-done">
                        <button @click="doAndroidInstall" class="but-2 but-c">
                        Install App
                        </button>
                    </div>
                </div>

            </div>

        </div>
        <div v-else>

            <p class="px-10 my-4 text-xl text-center">Mobile Experience Recommended</p>
            <p class="px-10 my-4 text-center">
                Enhance your gameplay by installing the game on your mobile phone. Go to <b>http://play.degangs.com</b> on your phone and enjoy a seamless mobile experience.
            </p>
            <div class="w-full text-center">
                <img src="@/assets/app-go.png" class="max-w-[300px] w-[80%] mx-auto">
            </div>


        </div>

        <div class="pb-10 text-center">
            <div class="mb-2 opacity-70 text-sm text-center">or continue playing in browser:</div>
            <button class="mt-2 but-4 but-b opacity-70 w-fit mx-auto" @click="isStandalone=true">
                Continue
            </button>
        </div>


    </div>

</div>
<div v-else class="bg-slate-900 bg-opacity-95 text-white md:w-[80%] min-h-screen md:max-w-[700px] w-full mx-auto md:border-l md:border-r md:border-slate-700">

    <div v-if="isMiniApp">

        <div v-if="!isChecking">

            <div v-if="!noUser">

                <div v-if="!isError">

                    <div v-if="!isLoading">

                        <!-- Profile -->
                        <div v-show="UserProfile" class="z-[98] bg-black bg-opacity-50 top-0 bottom-0 left-0 right-0 fixed"></div>
                        <div v-show="UserProfile" class="z-[99] bg-slate-800 border border-slate-600 shadow-slate-950 shadow-2xl fixed p-3 top-3 right-3 left-3 rounded-md bottom-3 overflow-x-auto">
                            <div class="flex flex-row gap-3 mb-2">
                                <h2>Profile</h2>
                                <button class="ml-auto but-5 but-b mt-1" @click="doUserProfile">X</button>
                            </div>
                            <div class="flex flex-col gap-3">
                              
                                <div class="border border-slate-700 rounded-md p-3">
                                    <div class=" flex flex-col gap-2">
                                        <h5>E-mail</h5>

                                        <div v-if="!doChange" class="flex flex-col gap-2">
                                            <div v-if="Data.user_data.user_mail">
                                                <div class="text-sm mb-3">Your E-mail address:</div>
                                                <div class="font-mono border border-slate-600 rounded-md p-2 px-3 flex flex-row text-base mb-1">
                                                    <div>{{ Data.user_data.user_mail }}</div>
                                                </div>
                                                <div class="text-sm mt-1 text-slate-400 mb-1">Can be used to access your account.</div>
                                            </div>
                                            <div v-else>
                                                <div class="text-sm mb-3">Subscribe to bi-weekly newsletter not to miss important announcements.</div>
                                                <button class="but-3 but-c gap-2 w-fit" @click="mailChange()">Enter E-mail</button>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <div v-if="inAction">
                                                <div class="spinner mx-auto mb-2"></div>
                                                <div class="mb-2 text-center">Sending request...</div>
                                            </div>
                                            <div v-else-if="gotError">
                                                <div v-if="gotErrorCode==1" class="text-yellow-200">{{ gotError }}</div>
                                                <div v-else class="text-yellow-200">{{ gotError }}</div>
                                                <button @click="closeAction()" class="but-4 but-b mt-2">Retry</button>
                                            </div>
                                            <div v-else-if="actionDone">
                                                <div class="text-slate-200">E-mail saved successfully.</div>
                                                <button @click="mailChange()" class="but-4 but-a mt-2">Close</button>
                                            </div>
                                            <div v-else class="flex flex-col gap-2">
                                                <div class="text-sm mb-1">Your e-mail adress:</div>
                                                <input type="text" v-model="eMail" class="text-black text-lg rounded-md border border-slate-500 p-2">
                                                <div class="text-sm mb-1 text-slate-400">We will send the link to confirm your e-mail.</div>
                                                <button class="but-3 but-a w-fit mb-1" @click="mailSave()">Save</button>
                                            </div>                    
                                        </div>
                                    </div>
                                </div>

                                <div class="border border-slate-700 rounded-md p-3">
                                    <h5>Settings</h5>
                                    <div class="mb-3 mt-1 text-sm">Fine tune your DeGangs experience:</div>
                                    <router-link :to="{ name: 'settings' }" @click.native="closeUserProfile()" class="but-4 but-c text-white">
                                        <img src="@/assets/icon/set.svg" class="w-5 mr-2 inline-block">
                                        Settings
                                    </router-link>
                                </div>

                                <button class="but-5 but-b gap-2 w-fit opacity-80" @click="logout()">Logout</button>



                            </div>
                        </div>

                        <!-- The App -->

                        <ListMenu v-if="openMenu" 
                            @doMenu="doMenu" 
                            @doUserProfile="doUserProfile" 
                            @doTutorial="doTutorial"
                            @closeTutorial="closeTutorial"
                            :TutorialShow="TutorialShow"
                            :TutorialStep="TutorialStep" 
                            :Data="Data" 
                            :DEBUG="DEBUG" 
                            @doDEBUG="doDEBUG" 
                        />

                        <MainMenu v-if="!openMenu"  
                            @doMenu="doMenu" 
                            @doUserProfile="doUserProfile" 
                            @doTutorial="doTutorial" 
                            @closeTutorial="closeTutorial"
                            :TutorialShow="TutorialShow" 
                            :TutorialStep="TutorialStep" 
                            @updateFight="updateFight"
                            :Data="Data" 
                            :DEBUG="DEBUG" 
                        />

                        <!-- <UserMenu v-show="userMenu" 
                            :Data="Data" 
                            :DEBUG="DEBUG" 
                            @doUserMenu="doUserMenu" 
                            :TON="TON" 
                            @connectWallet="connectWallet" 
                            :TONConnection="TONConnection" 
                        /> -->

                        <Tutorial v-if="TutorialShow" 
                            :TutorialShow="TutorialShow" 
                            :TutorialStep="TutorialStep" 
                            @closeTutorial="closeTutorial" 
                            :Data="Data" 
                            :DEBUG="DEBUG" 
                        />

                        <div v-if="!openMenu">
                            
                            <TopPanel 
                                @doMenu="doMenu" 
                                @doUserProfile="doUserProfile"
                                @doTutorial="doTutorial"
                                @closeTutorial="closeTutorial"
                                :updatingData="updatingData"
                                :TutorialShow="TutorialShow"
                                :TutorialStep="TutorialStep" 
                                :Data="Data" 
                                :DEBUG="DEBUG" 
                            />

                            <PanelHP v-if="showHP" 
                                :Data="Data" 
                                :DEBUG="DEBUG" 
                            />

                            <!-- <div class="fixed z-50 bottom-20 right-5 w-10 rounded-full h-10 bg-blue-500 p-2 shadow-2xl" @click="doMenu()"><img src="@/assets/icon/menu.svg" class=""></div> -->

                            <ModalPromo1 v-if="Promo1" 
                                @closeModal="closeModal"
                                :Data="Data"
                                :DEBUG="DEBUG"
                            />

                            <NewLevel v-if="LevelUp" 
                                :Data="Data"
                                :DEBUG="DEBUG" 
                                @closeLevelUp="closeLevelUp" 
                            />
                            <NewAchieve v-if="Achieve" 
                                :Data="Data" 
                                :DEBUG="DEBUG" 
                                @closeAchieve="closeAchieve" 
                            />
                                
                            <div class="pb-16">
                                <router-view
                                    :TONConnection="TONConnection" 
                                    @updateData="updateData" 
                                    @doUserProfile="doUserProfile" 
                                    @doTutorial="doTutorial"
                                    @closeTutorial="closeTutorial"
                                    :TutorialShow="TutorialShow" 
                                    :TutorialStep="TutorialStep" 
                                    :telegramData="telegramData" 
                                    :Data="Data" 
                                    :initData="initData" 
                                    :loadedData="loadedData" 
                                    :randReload="randReload"
                                    :lastUpdateTime="lastUpdateTime"
                                    :DEBUG="DEBUG" 
                                />
                                <!-- @skipTutorial="skipTutorial" -->
                            </div>

                        </div>

                    </div>

                    <div v-else class="bg-slate-900 h-screen flex flex-row justify-center text-center">
                        
                        <div class="my-auto text-center">
                            <div class="mb-4"><img src="@/assets/logo/dt.png" class="w-32 mx-auto"></div>
                            <div class="mb-8 uppercase text-zinc-400 text-sm">
                                    <div>
                                        <div v-if="loaderText === 1">Fight for $TON</div>
                                        <div v-if="loaderText === 2">Massively Multiplayer TON Game</div>
                                        <div v-if="loaderText === 3">Loot Toncoin from rivals</div>
                                        <div v-if="loaderText === 4">A Social Experiment</div>
                                        <div v-if="loaderText === 5">Your Mafia Gang in Telegram</div> 
                                    </div>
                            </div> 
                            <div class="mb-4 spinner mx-auto"></div>
                            <div class="mb-4 uppercase text-zinc-200">Loading</div> 
                        </div>

                    </div>
                </div>
                <div v-else>
                    <div class="pt-10">
                        <img src="@/assets/icon/warn-o.svg" class="w-14 h-14 mx-auto">
                        <p class="p-5  text-center">We have encountered an <span @click="doDEBUG()">error</span> while processing miniapp. Please try again later or contact us in the support group:</p>
                        <div class="w-full text-center">
                            <button @click="logout()" href="http://t.me/don_ton_chat" class="mt-2 but-4 but-a w-fit mx-auto">
                                Log out
                            </button>
                        </div>
                        <p class="p-5  text-center">The team is here to support you!</p>

                        <p class="border-t border-slate-600 p-5 text-sm text-center">Sending <b>/start</b> or <b>/retry</b> command to the bot may help in some situations.</p>
                        <p v-if="DEBUG" class="p-5 text-sm text-yellow-200 text-center">Error code: {{ isErrorText }}</p>
                    </div>
                </div>

                <!--<div class="debug" v-if="DEBUG">WebAppData = {{ WebAppData }}</div><hr>-->
                <!--<div class="debug" v-if="DEBUG">initData = {{ initData }}</div><hr>-->
                <div class="debug" v-if="DEBUG">Data = {{ Data }}</div>
                <div class="debug" v-if="DEBUG">telegramData = {{ telegramData }}</div>
            
            </div>
            <!-- / if nouser -->
            <div v-else>

                <div class="pt-10 max-w-[600px] md:w-[80%] mx-auto">
                    <p class="text-sm uppercase mb-5 opacity-50 text-center">Revolutonary social MMO Experiece</p>
                    <img src="@/assets/logo/dt.png" class="w-24 mx-auto mb-2">
                    <p class="px-10 my-4  text-center">Assemble your DeGangs squad &mdash; join today!</p>
                    <div class="w-full text-center">
                        <!-- <p class="px-10 my-4  text-center">Enter your e-mail:</p>
                        <div class="mb-2"><input text="text" name="eMail" v-model="eMail" class="rounded-sm text-lg p-2 text-black" placeholder="your@email.com"></div>
                        <button class="mt-2 but-3 but-a w-fit mx-auto" @click="sendEmail">
                            Start The Game
                        </button> -->

                        <div class="rounded-md p-3">


                            <div class="bg-slate-800 rounded-md p-4 mb-5">

                                <h3 class="mb-4">Login with Telegram</h3>

                                <div class="w-full text-center mb-5 mt-2">
                                    <!-- <a href="http://t.me/don_ton_bot" class="mt-2 but-3 but-a w-fit mx-auto">
                                        <img src="@/assets/icon/social/t.svg" class="w-5 mr-2 inline-block">
                                        Sign In
                                    </a> -->
                                    <!-- <div id="telegram-login-button"></div> -->
                                    <span v-if="!isTelegramLoaded">Loading...</span>
                                    <div class="w-fit mx-auto">
                                        <telegramLoginTemp
                                            mode="callback"
                                            telegram-login="degangs_bot"
                                            @loaded='telegramLoadedCallbackFunc'
                                            @callback="handleTelegramAuth"
                                        />
                                    </div>
                                    <div class="mt-5 text-slate-400 text-sm text-center">
                                        Use your <a href="https://telegram.org/" class="text-slate-400" target="_blank">Telegram messenger</a> account to play the game.
                                    </div>
                                </div>

                            </div>

                            <p v-if="error" class="border border-red-500 text-red-200 p-2 rounded-sm mb-5">{{ error }}</p>

                            <div class="bg-slate-800 rounded-md p-4">
                                <h3 class="mb-4">Login with E-mail</h3>

                                <div v-if="step === 'email'">
                                    <div class="mb-2"><input v-model="email" type="email" placeholder="Enter your email" class="w-[70%] rounded-md text-lg p-2 text-black"></div>
                                    <button @click="sendEmailCode" class="mt-2 but-3 but-a w-fit mx-auto">Start the Game</button>
                                </div>
                                <div v-else-if="step === 'code'">
                                    <div class="mb-2 px-10">We've sent a code to your e-mail adress {{ email}} &mdash; please enter the code to start the game:</div>
                                    <div class="mb-2">
                                        <input v-model="accessCode" type="text" placeholder="Enter access code" class="rounded-sm mr-2 text-lg p-2 text-black">
                                        <button @click="confirmCode" class="mt-2 but-3 but-a w-fit mx-auto">Confirm Code</button>
                                    </div>

                                    <div v-if="canSkip" class="mt-2 text-slate-400 text-sm text-center">
                                        <div>You can skip this step and start the game immediatelly: </div>
                                        <div class="mb-2">
                                            <a href="#" @click="skipCode" class="mt-2 but-4 but-b w-fit mx-auto">Skip</a>
                                        </div>
                                        <div class="text-orange-200 text-sm">Please note that for the next login we will require a code sent to this email to confirm the ownership of your account.</div>
                                        <!-- If you have no access to this email you will not be able to access your account! -->
                                    </div>
                                    <div v-else>
                                        <div class="mt-2 text-slate-400 text-sm text-center">
                                            <button class="mt-3 but-b but-5" @click="backToEmail()">Change E-mail</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            

                   
                        </div>



                    </div>
                    <!-- <div class="mt-5 text-sm text-slate-400 text-center">DeGangs is a massively multiplayer game on The Open Network.</div>
                    <img src="@/assets/spot1.jpg" class="w-[300px] rounded-xl mx-auto mt-4 border border-slate-800">
                    <p class="pt-4 px-10 text-sm text-center">Build the most powerful mafia gang in Telegram, 
                        <img src="@/assets/set/gun.svg" class="w-5 inline-block"> attack rival gangs for loot in 
                        <img src="@/assets/pics/cash.png" class="w-5 h-5 inline-block"> Cash, invest in properties and earn 
                        <img src="@/assets/icon/cup.svg" class="w-4 h-4 inline-block"> Cups to claim Toncoin and $USDT from the Treasury!
                    </p> -->
                    <div class="pb-10">
                        <div class="p-5 text-sm text-center">
                            Learn more about the DeGangs game:
                            <div class="mt-4"><a href="https://degangs.com" class="but-c but-4" target="_blank">Official Website</a></div>
                        </div>
                    </div>
                </div>

            </div><!-- / if nouser -->

        </div>
        <div v-else class="bg-slate-900 h-screen flex flex-row justify-center text-center">

            <div class="my-auto text-center">
                <div class="mb-4"><img src="@/assets/logo/dt.png" class="w-32 mx-auto"></div>
                <div class="mb-4 spinner mx-auto"></div>
                <div class="mb-4 uppercase text-zinc-200">Loading</div> 
            </div>

        </div>

    </div>
    
    <div v-else>
        <div class="pt-10">
            <img src="@/assets/logo/dt.png" class="w-32 mx-auto">
            <p class="p-5  text-center">Add this game to your mobile phone home screen.</p>
            <div class="w-full text-center">
                [here is how]
                <!-- <button @click="installApp" class="mt-2 but-4 but-a w-fit mx-auto">
                Add to Home Screen
                </button> -->
            </div>
            <div class="p-5 text-sm text-slate-400 text-center">The team is here to support you!</div>

            <div class="border-t border-slate-600 p-5 text-sm text-center">
                Learn more about the DeGangs game:
                <div class="mt-4"><a href="https://degangs.com" class="but-c but-3">Official Website</a></div>
            </div>
        </div>
        
    </div>

</div>
<div v-if="DEBUG" class="text-white text-sm text-center p-5">
    isStandalone = {{ isStandalone }}<br>
    isIOS = {{ isIOS }}<br>
    isAndroid = {{ isAndroid }}<br>
    showInstallPrompt = {{ showInstallPrompt }}<br>
    showAndroidLoader = {{ showAndroidLoader }}<br>
    deferredPrompt = {{ deferredPrompt }}<br>
</div>
</template>

<script>
// import * as TON_CONNECT_UI from '@tonconnect/ui';

import axios from 'axios';
// import { watch } from 'vue'
// import { useRouter } from 'vue-router'

import PanelHP from '@/components/PanelHP.vue';
import MainMenu from '@/components/MainMenu.vue';
import ListMenu from '@/components/Menu.vue';
import NewLevel from '@/components/NewLevel.vue';
import NewAchieve from '@/components/NewAchieve.vue';
import TopPanel from '@/components/TopPanel.vue';
import Tutorial from '@/components/Tutorial.vue';
import ModalPromo1 from '@/modal/Promo1.vue';

import { telegramLoginTemp } from 'vue3-telegram-login';
import router from './router';

export default {
  name: 'App',
    components: {
        PanelHP,
        MainMenu,
        ListMenu,
        NewLevel,
        NewAchieve,
        TopPanel,
        Tutorial,
        ModalPromo1,
        telegramLoginTemp,
    },
    watch: {
        '$route'() {
            this.checkRoute(); // for HP panel
        },
        UserProfile(value) {
            if (value) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = '';
            }
        }
    },
  data() {
    return {
        // TONConnection: null,
        // TONWallet: {
        //     Address: '',
        //     Public: '',
        //     Data: null,
        // },

        isLoggedIn: false,
        step: 'email',
        email: 'test@test.com', // !! REMOVE
        accessCode: '',
        // userEmail: '',
        error: '',
        isTelegramLoaded: false,

        DEBUG: false,
        initData: '',
        Data: { user_data: {} },

        isMiniApp: true,
        isLoading: true,
        isChecking: true,
        loaderText: 0,
        isError: false,
        isErrorText: '',

        userData: {},
        showHP: false,
        loadedData: false,

        LevelUp: false,
        Achieve: false,

        // email
        doChange: false,
        actionDone: false,
        actionResult: {},
        gotError: '',
        gotErrorCode: 0,
        inAction: false,

        clientData: null,

        noUser: true,
        isStandalone: true,

        TutorialShow: false,
        TutorialStep: 0,

        canSkip: false,

        teleData: {},
        telegramData: {
            initData: '',
            initDataUnsafe: {},
            version: '',
            platform: '',
            colorScheme: '',
            themeParams: {},
            isExpanded: false,
            viewportHeight: 0,
            viewportStableHeight: 0,
            headerColor: '',
            backgroundColor: '',
            isClosingConfirmationEnabled: false,
        },
        randReload: 0,
        Promo1: false,
        Promo2: false,
        updatingData: false,

        openMenu: false,
        UserProfile: false,
        lastUpdateTime: null,

        isStandalone: false,
        isIOS: false,
        isAndroid: false,
        showInstallPrompt: false,
        showAndroidLoader: false,
        doneAndroidLoader: false,

        fromCode: '',

        openedFrom: '',
        deferredPrompt: null,
    };
  },
  created() {

    this.checkAuthStatus();

  },
  mounted() {

    this.checkStandaloneMode();
    this.detectDevice();
    this.listenForInstallPrompt();

    const urlParams = new URLSearchParams(window.location.search);
    this.fromCode = urlParams.get('from') || '';

    if(process.env.VUE_APP_DEBUG==1) this.DEBUG=true;


    this.loaderText = Math.floor(Math.random() * 5) + 1;

    this.clientData = {
        userAgent: navigator.userAgent,
        language: navigator.language,
        // languages: navigator.languages || [navigator.language],
        platform: navigator.platform,
        cookieEnabled: navigator.cookieEnabled,
        screenWidth: window.screen.width,
        screenHeight: window.screen.height,
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight
    }

    this.checkRoute();
  

  },
  methods: {

    telegramLoadedCallbackFunc () {
        // alert('wigdet ok');
        console.log('script is loaded');
        this.isTelegramLoaded = true;
    },

    checkStandaloneMode() {
      this.isStandalone = window.matchMedia('(display-mode: standalone)').matches ||
                          window.navigator.standalone ||
                          document.referrer.includes('android-app://');
    },
    detectDevice() {
      const userAgent = window.navigator.userAgent.toLowerCase();
      this.isIOS = /iphone|ipad|ipod/.test(userAgent);
      this.isAndroid = /android/.test(userAgent);
    },
    listenForInstallPrompt() {
      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault();
        this.deferredPrompt = e;
        this.showInstallPrompt = true;
      });
    },
    showAndroidInstallLoader() {
      this.showAndroidLoader = true;
      setTimeout(() => {
        this.doneAndroidLoader = true;
        // this.triggerAndroidInstall();
      }, 5000);
    },
    doAndroidInstall() {
      if (this.deferredPrompt) {
        this.deferredPrompt.prompt();
        this.deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the install prompt');
          } else {
            console.log('User dismissed the install prompt');
          }
          this.deferredPrompt = null;
        });
      }
      this.showAndroidLoader = false;
    },


    handleTelegramAuth(user) {
        // alert('user='+JSON.stringify(user));
        // {
        // "id": 7192008371,
        // "first_name": "Don",
        // "last_name": "Tonny",
        // "username": "don_tonny",
        // "photo_url": "https://t.me/i/userpic/320/facsIiPmpQfSHXNYWl6Bgrn3VAagPI7CFx8k8j3-Muq-pp7dR17HSrNBy_3tC3bm.jpg",
        // "auth_date": 1720302552,
        // "hash": ""
        // }

      axios.post(`${process.env.VUE_APP_API_URL}auth/telegram`, {
        request: { user, from: this.fromCode }
      })
        .then(response => {
            // alert('done' + JSON.stringify(response.data) + '!');
            if (response.data.status === 'success') {
                localStorage.setItem('jwt', response.data.jwt);
                // this.noUser = false;
                this.initData = response.data.jwt;
                this.isLoading = true;
                this.updateData().then(() => {
                    this.noUser = false;
                });
                // this.isLoggedIn = true;
                // this.userEmail = user.email || 'Telegram User';
                this.error = '';
            } else {
                // alert('error telegram auth');
                this.error = response.data.message || 'Authentication failed';
            }
        })
        .catch(error => {
          console.error('Telegram auth error:', error);
        //   alert('error - '+error);
          this.error = 'Authentication failed. Please try again.';
        });
    },

    checkAuthStatus() {
      const token = localStorage.getItem('jwt');     
      // console.log ('checkAuthStatus token='+token);
      if (token) {
        this.verifyToken(token);
        // this.isLoggedIn = true;
        // this.userEmail = localStorage.getItem('userEmail');
      } else {
        this.noUser = true;
        this.isChecking=false;
      }
    },

    verifyToken(token) {
      axios.post(`${process.env.VUE_APP_API_URL}auth/check`, {
        initData: token // headers: { Authorization: `Bearer ${token}` }
      })
      .then(response => {
        // this.isLoggedIn = true;
        // alert('OK '+JSON.stringify(response.data)+'!');
        // this.noUser = false;
        this.initData = token;
        // this.updateData();
        this.updateData().then(() => {
                this.noUser = false;
                this.isChecking=false;
            });
        // .then(() => {
        //   // this.isLoading=false;
        // })
        // .catch(error => {
        //   console.error('Error:', error.response || error);
        //   alert('error' + error);
        //   localStorage.removeItem('jwt');
        //   localStorage.removeItem('refreshToken');
        //   // localStorage.removeItem('userEmail');
        //   this.isLoading=false;
        // });

      }).catch(error => {
        console.error('Error:', error.response || error);
        // alert('error response verifyToken:' + JSON.stringify(error.response));
        // alert(error.response.data.no_auth+'!')
        if(error.response.data.no_auth && error.response.data.no_auth==1) {
            // alert('Session lost. Please login again.');
            this.logout();
            this.$router.push({ name: 'home' });
            
            // const token = localStorage.getItem('refreshToken');
            // if(token) {
            //     axios.post(`${process.env.VUE_APP_API_URL}auth/refresh`, {
            //         request: { refresh_token: token }
            //     })
            //     .then(response => {
            //         // alert('refreshed '+JSON.stringify(response.data));
            //         console.log('refreshed '+JSON.stringify(response.data));
            //         localStorage.setItem('jwt', response.data.jwt);
            //         // localStorage.setItem('refreshToken', response.data.refresh_token);
            //         this.initData = response.data.jwt;
            //         this.updateData().then(() => {
            //             this.noUser = false;
            //             this.isChecking=false;
            //         });
            //     });
            // } 
        }
        // alert('error verifyToken:' + JSON.stringify(error));
        // localStorage.removeItem('jwt');
        // localStorage.removeItem('refreshToken');
        // localStorage.removeItem('userEmail');
        this.isLoading=false;
      });
    },

    sendEmailCode() {
      this.isLoading = true;
      this.error = '';
      axios.post(`${process.env.VUE_APP_API_URL}auth/signup`, { 
        request: { email: this.email, from: this.fromCode }
       })
        .then(response => {
          console.log('Response:', response.data); // Add this line for debugging
          if (response.data.status === 'success') {
            this.step = 'code';
            if(response.data.can_skip == 1) this.canSkip = true; else this.canSkip = false;
          } else {
            this.error = response.data.message || 'An error occurred';
          }
        })
        .catch(error => {
          console.error('Error:', error.response || error);
          this.error = error.response?.data?.message || 'An error occurred. Please try again.';
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    confirmCode() {
      this.isLoading = true;
      this.error = '';
      axios.post(`${process.env.VUE_APP_API_URL}auth/confirm`, { 
        request: { email: this.email, access_code: this.accessCode, from: this.fromCode }
      })
        .then(response => {
          console.log('Response:', response.data); // Add this line for debugging
          if (response.data.status === 'success') {
            localStorage.setItem('jwt', response.data.jwt);
            localStorage.setItem('refreshToken', response.data.refresh_token);
            // localStorage.setItem('userEmail', this.email);
            
            this.initData = response.data.jwt;
            this.isLoading = true;
            this.updateData().then(() => {
                this.noUser = false;
            });
            // this.isLoggedIn = true;
            // this.userEmail = this.email;
          } else {
            this.error = response.data.message || 'An error occurred';
          }
        })
        .catch(error => {
          console.error('Error:', error.response || error);
          this.error = error.response?.data?.message || 'An error occurred. Please try again.';
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    skipCode() {
      this.isLoading = true;
      this.error = '';
      axios.post(`${process.env.VUE_APP_API_URL}auth/skip`, { 
        request: { email: this.email, from: this.fromCode }
      })
        .then(response => {
          console.log('Response:', response.data); // Add this line for debugging
          if (response.data.status === 'success') {
            localStorage.setItem('jwt', response.data.jwt);
            localStorage.setItem('refreshToken', response.data.refresh_token);
            // localStorage.setItem('userEmail', this.email);
            // this.noUser = false;
            this.initData = response.data.jwt;
            this.isLoading = true;
            this.updateData().then(() => {
                this.noUser = false;
            });
            // this.isLoggedIn = true;
            // this.userEmail = this.email;
          } else {
            this.error = response.data.message || 'An error occurred';
          }
        })
        .catch(error => {
          console.error('Error:', error.response || error);
          this.error = error.response?.data?.message || 'An error occurred. Please try again.';
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    backToEmail() {
        this.step = 'email';
        this.email = '';
        this.error = '';
    },

    logout() {
      localStorage.removeItem('jwt');
      localStorage.removeItem('refreshToken');
      // localStorage.removeItem('userEmail');
      this.isLoggedIn = false;
      this.step = 'email';
      this.email = '';
      this.accessCode = '';
      // this.userEmail = '';
      this.initData = '';
      this.noUser = true;
    },

    // getDisplayMode() {
    //   if (window.matchMedia('(display-mode: standalone)').matches) {
    //     return 'standalone';
    //   } else if (window.matchMedia('(display-mode: fullscreen)').matches) {
    //     return 'fullscreen';
    //   } else if (window.matchMedia('(display-mode: minimal-ui)').matches) {
    //     return 'minimal-ui';
    //   } else {
    //     return 'browser';
    //   }
    // },

    updateData() {

        return new Promise((resolve, reject) => {

            this.lastUpdateTime = Date.now();
            this.updatingData = true;

            // alert('updating with '+this.initData);
            // const token = localStorage.getItem('jwt'); // stored in initData now
            
            axios.post(`${process.env.VUE_APP_API_URL}`, {
                initData: this.initData,
                // headers: { Authorization: `Bearer ${token}` }
            })
            .then(response => {

                if(response.data.status && response.data.status=='error') { 

                    if(response.data.no_user && response.data.no_user==1) {
                        this.noUser = true;
                    } else { 
                        this.isError = true;
                        if(response.data.message) this.isErrorText = response.data.message;
                        else this.isErrorText = 'API connection error.';
                        // console.error('Error API:', error);
                    }
                    this.isLoading = false;
                    this.updatingData = false;
                    resolve();
                    
                } else {

                    this.Data = response.data.data;

                    // alert ('THIS DATA user_data ## '+JSON.stringify(this.Data.user_data));

                    this.userData = this.Data.user_data;
                    this.loadedData = true;
                    this.isError = false;
                    this.isErrorText= '';

                    if(this.Data.user_data.levelup==1) {
                        if(this.$route.name!='levelup')
                        this.LevelUp = true;
                    }

                    if(this.Data.user_data.achieve>0) {
                        if(this.$route.name!='achieve')
                        this.Achieve = true;
                    }

                    // if(this.Data.promo && this.Data.promo['1']) 
                    // {
                    //     this.showPromo(1);
                    // }
                    this.showPromo(1);

                    this.isLoading = false;
                    this.updatingData = false;
                    resolve();

                }

            }).catch((error) => {
                this.isError = true;
                this.isErrorText = 'ERROR = ' + error;
                // console.error('Error posting data:', error);
                this.updatingData = false;
                reject();
            });

        });

    },

    showPromo(promo) {

        axios.post(process.env.VUE_APP_API_URL+'promo_show', {
            initData: this.initData,
        }).then((response) => {
            // const res1=response.data;
            // alert('json='+JSON.stringify(response.data.promo1));
            if(response.data && response.data.promo1==1) this.Promo1 = true;
            
        }).catch((error) => {
            // console.error('Error posting data:', error);
        });

    },

    mailChange() {
        this.closeAction();
        this.doChange = !this.doChange;
    },

    mailSave() {
        this.inAction = true;

        if(this.Data.user_data.user_name == this.gangName) {
            this.inAction = false;
            this.gotError = 'The e-mail is the same as the current one.';
            return;
        }
        axios.post(process.env.VUE_APP_API_URL+'settings/mail', {
            initData: this.initData,
            request: { email: this.eMail },
        }).then((response) => {
            // alert(JSON.stringify(response.data));
            if(response.data.status === 'error') {
                this.gotError = response.data.message;
                this.gotErrorCode = response.data.error_code;
            } else {
                this.actionDone = true;
                this.actionResult = response.data;
                this.Data.user_data.user_mail = this.eMail;
                // if(this.actionResult.result && this.actionResult.result>0) this.$emit('updateData');
            }
            this.inAction = false;
        }).catch((error) => {
            this.gotError = error;
            console.error('Error:', error);
            this.inAction = false;
        });

    },
    closeAction() {
        this.actionDone = false;
        this.actionResult = {};
        this.gotError = '';
        this.inAction = false;
    },

    updateFight() {
        this.randReload=Math.floor(Math.random() * 1000);
    },

    checkRoute() {// Open the share dialog

        if(
         this.$route.name=='restore' ||
         this.$route.name=='fight' ||
         this.$route.name=='quest'
        ) this.showHP=true;
        else this.showHP=false;
        
    },

    doTutorial(step) {
      if(this.TutorialShow) {
        // alert('doTutorial for step' +step ); //!//
        this.TutorialStep=step;
        if(step==10) this.TutorialShow=false;
        axios.post(process.env.VUE_APP_API_URL+'tutorial', {
            initData: this.initData,
            request: { step: step },
        }).then((response) => {
            // alert('SetStep result = '+JSON.stringify(response.data));
            this.Data.user_data.user_tutorial = step;
        }).catch((error) => {
            // alert('error'+error);
            // console.error('Error posting data:', error);
        });
      }
    },

    closeTutorial() {
        this.TutorialShow=false;
    },

    doMenu () {
        this.openMenu = !this.openMenu;
    },
    doUserProfile () {
        this.UserProfile = !this.UserProfile;
    },
    closeUserProfile() {
        this.UserProfile = false;
    },
    closeLevelUp () {
        this.LevelUp = false;
    },
    closeAchieve () {
        this.Achieve = false;
    },
    closeModal () {
        this.Promo1 = false;
        // this.Promo2 = false;
    },

    doDEBUG() {
        this.DEBUG = !this.DEBUG;
        // alert('DEBUG = '+this.DEBUG);
    },

  }
};


    // this.isLoading = false;


    // let isInStandaloneMode = false;

    // Check if standalone mode is supported
    // if ('standalone' in window.navigator) {
    //     isInStandaloneMode = window.navigator.standalone;
    // } else if (window.matchMedia('(display-mode: standalone)').matches) {
    //     alert('standalone check2');
    //     isInStandaloneMode = true;
    // }

    // this.openedFrom = isInStandaloneMode ? 'Opened from home screen shortcut' : 'Opened in browser';
    

    // alert('display mode='+this.getDisplayMode());
    // if (window.matchMedia('(display-mode: standalone)').matches) {

    //     alert('standalone check1');

    // } else {
    //    this.isMiniApp = false;
    // }


    // window.addEventListener('beforeinstallprompt', this.handleBeforeInstallPrompt);

//     if ('serviceWorker' in navigator) {
//     navigator.serviceWorker.register('https://play.degangs.com/service-worker.js')
//       .then(reg => console.log('Service worker registered:', reg))
//       .catch(err => console.error('Service worker error:', err));
//   }

//   if ('onbeforeinstallprompt' in window) {
//     console.log('beforeinstallprompt event is supported');
//   } else {
//     console.log('beforeinstallprompt event is not supported');
//   }

//   window.addEventListener('beforeinstallprompt', this.handleBeforeInstallPrompt);

//   if (window.matchMedia('(display-mode: standalone)').matches) {
//     console.log('App is already installed');
//   }


    // loadTelegramScript() {
    // //   const script = document.createElement('script');
    // //   script.async = true;
    // //   script.src = "https://telegram.org/js/telegram-widget.js?22";
    // //   script.setAttribute("data-telegram-login", "degangs_bot"); // Replace with your bot name
    // //   script.setAttribute("data-size", "large");
    // //   script.setAttribute("data-onauth", "onTelegramAuth(user)");
    // //   script.setAttribute("data-request-access", "write");
    // //   document.head.appendChild(script);
     
    //   // Define global onTelegramAuth function
    //   window.onTelegramAuth = this.handleTelegramAuth;
    // },
    


    // if ('serviceWorker' in navigator) {
    // window.addEventListener('load', () => {
    //     navigator.serviceWorker.register('/service-worker.js')
    //     .then(registration => {
    //         console.log('Service Worker registered:', registration);
    //     })
    //     .catch(error => {
    //         console.log('Service Worker registration failed:', error);
    //     });
    // });
    // }


    // signEmail() {

    //     axios.post(process.env.VUE_APP_API_URL+'auth', {
    //                 initData: this.initData,
    //                 email: this.eMail,
    //             }).then((response) => {

    //                 alert(JSON.stringify(response.data)+'!');

    //             }).catch((error) => {
    //                 this.isError = true;
    //                 this.isErrorText = 'ERROR = ' + error;
    //                 console.error('Error:', error);
    //             });

    // },

    // handleBeforeInstallPrompt(e) {
    //     e.preventDefault();
    //     this.deferredPrompt = e;
    //     console.log('beforeinstallprompt event was fired');
    // },

    // async installApp() {
    //   if (!this.deferredPrompt) {
    //     alert('no deferredPrompt');
    //     return;
    //   }
    //   this.deferredPrompt.prompt();
    //   const { outcome } = await this.deferredPrompt.userChoice;
    //   alert(`User response to the install prompt: ${outcome}`);
    //   this.deferredPrompt = null;
    // },

    // installApp() {
    //   if (this.deferredPrompt) {
    //     this.deferredPrompt.prompt();
    //     this.deferredPrompt.userChoice.then((choiceResult) => {
    //       if (choiceResult.outcome === 'accepted') {
    //         console.log('User accepted the A2HS prompt');
    //       } else {
    //         console.log('User dismissed the A2HS prompt');
    //       }
    //       this.deferredPrompt = null;
    //     });
    //   } else {
    //     alert('no deferredPrompt');
    //   }
    // },


</script>

<style>
@import "@/assets/index.css";
/* @tailwind base;
@tailwind components;
@tailwind utilities; */
</style>
