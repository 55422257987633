<template>

    <!-- TutorialStep = {{ TutorialStep }}, 
    currentPage = {{ currentPage }} -->
    
    <div v-show="TutorialStep==0" class="border border-slate-600 bg-slate-950 bg-opacity-90 shadow-slate-950 shadow-xl fixed p-3 rounded-md bottom-16 mb-3 right-3 left-3 z-[51]">
        <div class="absolute top-[42px] left-[48%]"><img src="@/assets/pics/arr.png" class="w-12"></div>
        <div class="flex flex-row gap-3">
            <div class="shrink-0 z-[55]">
                <img src="@/assets/pics/tutor.png" class=" w-12 h-12 border border-slate-500 rounded-full">
            </div>
            <div class="flex-grow">
                <div class="font-bold">Welcome to DeGangs!</div>
                <div class="text-sm pb-1">Start your day:</div>
            </div>
            <div class="ml-auto flex flex-col gap-2">
                <button class="ml-auto but-5 but-b text-sm opacity-40" @click="closeTutorial">X</button>
                <!-- <button class="ml-auto but-5 but-c text-sm opacity-40" @click="skipTutorial">Skip</button> -->
            </div>
        </div>
    </div> 

    <!-- <div v-show="TutorialStep==1 && currentPage=='fight'" class="z-[29] border border-slate-600 bg-slate-950 bg-opacity-90 shadow-slate-950 shadow-xl absolute p-3 rounded-md top-[390px] xs:top-[320px] mb-3 right-3 left-3">
        <div class="absolute top-[-20px] right-[20%]"><img src="@/assets/pics/arr-t.png" class="w-12"></div>
        <div class="flex flex-row gap-3">
            <div class="shrink-0 z-[55]">
                <img src="@/assets/pics/tutor.png" class=" w-12 h-12 border border-slate-500 rounded-full">
            </div>
            <div class="flex-grow">
                <div class="font-bold">Choose a Rival</div>
                <div class="text-sm pb-1">Hit the Attack button</div>
            </div>
            <div class="ml-auto flex flex-col gap-2">
                <button class="ml-auto but-5 but-b text-sm opacity-40" @click="closeTutorial">X</button>
                <button class="ml-auto but-5 but-c text-sm opacity-40" @click="skipTutorial">Skip</button>
            </div>
        </div>
    </div>  -->

    <div v-show="TutorialStep==2" class="border border-slate-600 bg-slate-950 bg-opacity-90 shadow-slate-950 shadow-xl fixed p-3 rounded-md bottom-16 mb-3 right-3 left-3 z-[51]">
        <div class="absolute top-[24px] left-[68%]"><img src="@/assets/pics/arr.png" class="w-12"></div>
        <div class="flex flex-row gap-3">
            <div class="shrink-0 z-[55]">
                <img src="@/assets/pics/tutor.png" class=" w-12 h-12 border border-slate-500 rounded-full">
            </div>
            <div class="flex-grow">
                <div class="font-bold">Get Better Weapons</div>
                <div class="text-sm pb-1">Manage your Weapons</div>
            </div>
            <div class="ml-auto flex flex-col gap-2">
                <button class="ml-auto but-5 but-b text-sm opacity-40" @click="closeTutorial">X</button>
                <!-- <button class="ml-auto but-5 but-c text-sm opacity-40" @click="skipTutorial">Skip</button> -->
            </div>
        </div>
    </div> 

    <!-- <div v-show="TutorialStep==3 && currentPage=='armory'" class="z-[29] border border-slate-600 bg-slate-950 bg-opacity-90 shadow-slate-950 shadow-xl absolute p-3 rounded-md top-[420px] xs:top-[380px] mt-10 mb-3 right-3 left-3">
        <div class="absolute top-[-20px] left-[100px]"><img src="@/assets/pics/arr-t.png" class="w-12"></div>
        <div class="flex flex-row gap-3 pt-10">
            <div class="shrink-0 z-[55]">
                <img src="@/assets/pics/tutor.png" class=" w-12 h-12 border border-slate-500 rounded-full">
            </div>
            <div class="flex-grow">
                <div class="font-bold">Grab the Gun</div>
                <div class="text-sm pb-1">To equip your Gangster</div>
            </div>
            <div class="ml-auto flex flex-col gap-2">
                <button class="ml-auto but-5 but-b text-sm opacity-40" @click="closeTutorial">X</button>
                <button class="ml-auto but-5 but-c text-sm opacity-40" @click="skipTutorial">Skip</button>
            </div>
        </div>
    </div>  -->

    <div v-show="TutorialStep==4" class="border border-slate-600 bg-slate-950 bg-opacity-90 shadow-slate-950 shadow-xl fixed p-3 rounded-md bottom-16 mb-3 right-3 left-3 z-[51]">
        <div class="absolute top-[40px] left-[14%]"><img src="@/assets/pics/arr.png" class="w-12"></div>
        <div class="flex flex-row gap-3">
            <div class="shrink-0 z-[55]">
                <img src="@/assets/pics/tutor.png" class=" w-12 h-12 border border-slate-500 rounded-full">
            </div>
            <div class="flex-grow">
                <div class="font-bold pl-6">Do The Job</div>
                <div class="text-sm pb-1 pl-6">For EXP and <img src="@/assets/pics/cash.png" class="w-5 h-5 inline-block"> Cash</div>
            </div>
            <div class="ml-auto flex flex-col gap-2">
                <button class="ml-auto but-5 but-b text-sm opacity-40" @click="closeTutorial">X</button>
                <!-- <button class="ml-auto but-5 but-c text-sm opacity-40" @click="skipTutorial">Skip</button> -->
            </div>
        </div>
    </div>

    <!-- <div v-show="TutorialStep==5 && currentPage=='quest'" class="z-[29] border border-slate-600 bg-slate-950 bg-opacity-90 shadow-slate-950 shadow-xl absolute p-3 rounded-md top-80 mt-5 right-3 left-3">
        <div class="absolute top-[-20px] right-[50px]"><img src="@/assets/pics/arr-t.png" class="w-12"></div>
        <div class="flex flex-row gap-3">
            <div class="shrink-0">
                <img src="@/assets/pics/tutor.png" class="w-12 h-12 border border-slate-500 rounded-full">
            </div>
            <div class="flex-grow">
                <div class="font-bold">Do the Job!</div>
                <div class="text-sm">Uses <img src="@/assets/set/energy.svg" class="w-5 h-5 inline-block"> Energy for every run.</div>
            </div>
            <div class="ml-auto flex flex-col gap-2">
                <button class="ml-auto but-5 but-b text-sm opacity-40" @click="closeTutorial">X</button>
                <button class="ml-auto but-5 but-c text-sm opacity-40" @click="skipTutorial">Skip</button>
            </div>
        </div>
    </div> -->
    
    <div v-show="TutorialStep==6" class="z-[51] border border-slate-600 bg-slate-950 bg-opacity-90 shadow-slate-950 shadow-xl fixed p-3 rounded-md bottom-16 mb-3 right-3 left-3">
        <div class="absolute top-[40px] left-[2%]"><img src="@/assets/pics/arr-l.png" class="w-12"></div>
        <div class="flex flex-row gap-3">
            <div class="shrink-0 z-[55]">
                <img src="@/assets/pics/tutor.png" class=" w-12 h-12 border border-slate-500 rounded-full">
            </div>
            <div class="flex-grow">
                <div class="font-bold">Collect Profit</div>
                <div class="text-sm">Your income in <img src="@/assets/pics/cash.png" class="w-5 h-5 inline-block"> Cash is ready to be collected every next hour.</div>
            </div>
            <div class="ml-auto flex flex-col gap-2">
                <button class="ml-auto but-5 but-b text-sm opacity-40" @click="closeTutorial">X</button>
                <!-- <button class="ml-auto but-5 but-c text-sm opacity-40" @click="skipTutorial">Skip</button> -->
            </div>
        </div>
    </div> 

    <!-- <div v-show="TutorialStep==7 && currentPage=='home'" class="z-[29] border border-slate-600 bg-slate-950 bg-opacity-90 shadow-slate-950 shadow-xl absolute p-3 rounded-md top-[620px] mt-5 right-3 left-3">
        <div class="absolute top-[-20px] left-[50px]"><img src="@/assets/pics/arr-t.png" class="w-12"></div>
        <div class="flex flex-row gap-3">
            <div class="shrink-0 z-[55]">
                <img src="@/assets/pics/tutor.png" class=" w-12 h-12 border border-slate-500 rounded-full">
            </div>
            <div class="flex-grow ml-8">
                <div class="font-bold">Press Invite</div>
                <div class="text-sm pb-1">The best way to increase the power of your Gang!</div>
            </div>
            <div class="ml-auto flex flex-col gap-2">
                <button class="ml-auto but-5 but-b text-sm opacity-40" @click="closeTutorial">X</button>
                <button class="ml-auto but-5 but-c text-sm opacity-40" @click="skipTutorial">Skip</button>
            </div>
        </div>
    </div>  -->

    <div v-show="TutorialStep==8" class="z-[51] border border-slate-600 bg-slate-950 bg-opacity-90 shadow-slate-950 shadow-xl fixed p-3 rounded-md top-14 mb-3 right-3 left-3">
        <div class="absolute top-[-20px] right-[-3px]"><img src="@/assets/pics/arr-t.png" class="w-12"></div>
        <div class="flex flex-row gap-3">
            <div class="shrink-0 z-[55]">
                <img src="@/assets/pics/tutor.png" class=" w-12 h-12 border border-slate-500 rounded-full">
            </div>
            <div class="flex-grow">
                <div class="font-bold">Grow Your Gang</div>
                <div class="text-sm">Recruit Gangsters to increase the power of your Gang!</div>
            </div>
            <div class="ml-auto flex flex-col gap-2 mt-10">
                <button class="ml-auto but-5 but-b text-sm opacity-40" @click="closeTutorial">X</button>
                <!-- <button class="ml-auto but-5 but-c text-sm opacity-40" @click="skipTutorial">Skip</button> -->
            </div>
        </div>
    </div> 
        
</template>

<script>
export default {
    props: {
        Data: Object,
        DEBUG: Boolean,
        TutorialShow: Boolean,
        TutorialStep: Number,
  },
  watch: {
        '$route'() {
            this.checkRoute(); 
        },
    },
    components: {
        // panelGems
    },
    data() {
        return {
            currentPage: '',
        }
    },
    mounted() {
        this.userData = this.Data.user_data;
        this.checkRoute();
    },
    methods: {

        checkRoute() {
            this.currentPage=this.$route.name;
        },

        // doUserMenu() { this.$emit('doUserMenu'); },

        closeTutorial() { this.$emit('closeTutorial'); },

    }
}
</script>