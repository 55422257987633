<template>
    <div class="bg-slate-800 border-b border-slate-600 shadow-slate-950 shadow-xl sticky top-12 z-40">
        <div class="flex flex-row gap-2">
            <router-link :to="{ name: 'restore' }" class="group cursor-pointer w-1/3 border-r border-slate-600 p-2 flex flex-col">
                <div class="text-sm text-slate-500 uppercase flex flex-row gap-0">
                    <div class="w-1/6 shrink-0 mr-1"><img src="@/assets/set/hp.svg" class="w-4 h-4"></div>
                    <div class="w-5/6">
                        <div class="flex items-center bg-gray-200 h-4 rounded-md overflow-hidden border-slate-400 border-b border-r">
                            <!-- <div class="bg-gradient-to-b from-red-500 to-red-800 h-full text-white text-right text-[11px] font-bold" :style="{ width: Data.user_data.user_health + '%' }"> -->
                            <div class="bg-gradient-to-b from-red-500 to-red-800 h-full text-white text-right text-[11px] font-bold" :style="{ width: Data.user_data.user_health + '%' }">
                                <!-- <div class="absolute right-0 mt-[-3px] pr-1 text-red-300">{{ Data.user_data.user_hp }} HP</div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-sm text-slate-400 flex flex-row max-xs:hidden mt-2">
                    <div class="text-white text-[12px] font-bold">{{ Data.user_data.user_health }}%</div>
                    <div v-if="Data.user_data.user_health<100" class="ml-auto but-6 but-r group-hover:opacity-85">Restore</div>
                    <div v-else class="ml-auto but-6 but-b opacity-50 group-hover:opacity-85">Restore</div>
                </div>
            </router-link>
            <router-link :to="{ name: 'quest' }" class="group cursor-pointer w-1/3 border-r border-slate-600 p-2 flex flex-col">
                <div class="text-sm text-slate-500 uppercase flex flex-row gap-0">
                    <div class="w-1/6 shrink-0 mr-1"><img src="@/assets/set/energy.svg" class="w-5 h-5"></div>
                    <div class="w-5/6">
                        <div class="flex items-center bg-gray-200 h-4 rounded-md overflow-hidden border-slate-400 border-b border-r">
                            <div class="bg-gradient-to-b from-yellow-500 to-yellow-800 h-full" :style="{ width: (Data.user_data.user_energy / Data.user_data.user_energy_max) * 100 + '%' }"></div>
                        </div>
                    </div>
                </div>
                <div class="text-sm text-slate-400 flex flex-row max-xs:hidden mt-1">
                    <div class="text-white text-[12px] font-bold">{{ Data.user_data.user_energy }} / {{ Data.user_data.user_energy_max }}</div>
                    <div class="ml-auto but-6 but-b opacity-50 group-hover:opacity-85">Jobs</div>
                </div>
            </router-link>
            <router-link :to="{ name: 'fight' }"  class="group cursor-pointer w-1/3 p-2 flex flex-col">
                <div class="text-sm text-slate-500 uppercase flex flex-row gap-2">
                    <div class="w-1/6 shrink-0"><img src="@/assets/set/gun.svg" class="w-5 h-5"></div>
                    <div class="w-5/6">
                        <div class="flex items-center bg-gray-200 h-4 rounded-md overflow-hidden border-slate-400 border-b border-r">
                            <div class="bg-gradient-to-b from-orange-500 to-orange-800 h-full" :style="{ width: (Data.user_data.user_stamina / Data.user_data.user_stamina_max) * 100 + '%' }"></div>
                        </div>
                    </div>
                </div>
                <div class="text-sm text-slate-400 flex flex-row max-xs:hidden mt-1">
                    <div class="text-white text-[12px] font-bold">{{ Data.user_data.user_stamina }} / {{ Data.user_data.user_stamina_max }}</div>
                    <div class="ml-auto but-6 but-b opacity-50 group-hover:opacity-85">Attack</div>
                </div>
            </router-link>
        </div>
    </div> 
</template>

<script>
  export default {
    props: {
        Data: Object,
        DEBUG: Boolean,
    },
    data() {
        return {

        }
    },
    mounted() {
        // this.Data.user_data = this.Data.user_data;
    },
}
</script>